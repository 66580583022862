import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  check(e) {
    const checked = e?.params?.checked ?? e.target.checked;
    this.updateTargets(checked);
  }

  checkIfEql(e) {
    const { checked, eqlTo } = e?.params;
    if (e?.target.value === eqlTo) {
      this.updateTargets(checked);
    }
  }

  updateTargets(checked) {
    this.inputTargets.forEach((element) => (element.checked = checked));
  }
}
