import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery'

export default class extends Controller {
  connect() {
    let options = {};

    try {
      options = JSON.parse(this.data.get('options'));
    } catch(e) {}

    this.$collapse = jQuery(this.element).collapse(options);
  }
  disconnect() {
    this.$collapse.collapse('dispose');
  }
}
