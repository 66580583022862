import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery'

import gql from 'graphql-tag'
import { apolloAutocompleteClient as apolloClient } from '../global/apollo'

export const query = gql`
  query($id: ID!) {
    zendeskContact(id: $id) {
      addressCity
      addressStreet
      addressStreetNumber
      addressZipCode
      id
      name
    }
  }
`;

export default class extends Controller {
  static targets = [ "name", "selectionSource", "addressZipCode", "addressCity", "addressStreet", "addressStreetNumber" ];

  connect() {
    document.addEventListener('change', this.onChange.bind(this));
    jQuery(document).on('change', this.onChange.bind(this)); // select2 triggers jquery events
  }

  disconnect() {
    document.removeEventListener('change', this.onChange.bind(this))
    jQuery(document).off('change'); // select2 triggers jquery events
  }

  onChange(event) {
    if (event.target !== this.selectionSourceTarget) {
      return;
    }

    const value = this.selectionSourceTarget.value;

    this.prefill(value);
  }

  prefill(companyId) {
    const variables = { id: companyId };

    apolloClient
      .query({query: query, variables })
      .then((response) => {

        return response.data.zendeskContact;
      })
      .then(this.fillFields.bind(this));
  }

  fillFields(contact) {
    this.setSelect(this.addressZipCodeTarget, contact.addressZipCode);

    this.setSelect(this.addressCityTarget, contact.addressCity);

    this.setSelect(this.addressStreetTarget, contact.addressStreet);

    this.setInput(this.addressStreetNumberTarget, contact.addressStreetNumber);

    this.setInput(this.nameTarget, contact.name);
  }

  setInput(element, value, setWith = (input, value) => { input.value = value; }) {
    element.disabled = false;

    setWith(element, value)

    const changeEvent = new Event('change');
    element.dispatchEvent(changeEvent);
  }

  setSelect(element, value) {
    const val = value ? value : '';

    this.setInput(element, val, (input, value) => {
      const option = new Option(value, value, false, true);
      input.append(option);
    });
  }
}
