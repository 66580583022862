import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "empty", "content" ]

  static values = {
    ignoreSelector: { type: String, default: '.spinner-border' }
  }

  connect() {
    if(!this.hasEmptyTarget || !this.hasContentTarget) {
      return
    }

    this.observer = new MutationObserver(this.update.bind(this))
    this.observer.observe(this.contentTarget, {
      childList: true,
      attributes: false,
      subtree: true
    });
    this.update()
  }

  disconnect() {
    if(this.observer) {
      this.observer.disconnect()
    }
  }

  update() {
    if(this.hasContent()) {
      this.contentTarget.classList.remove('tw-hidden')
      this.emptyTarget.classList.add('tw-hidden')
    } else {
      this.contentTarget.classList.add('tw-hidden')
      this.emptyTarget.classList.remove('tw-hidden')
    }
  }

  hasContent() {
    return this.contentTarget.innerText !== '' || (
      this.ignoreSelectorValue !== '' &&
      this.contentTarget.querySelector(this.ignoreSelectorValue) !== null
    )
  }
}
