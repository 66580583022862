import { Controller } from "@hotwired/stimulus"

import jQuery from 'jquery'
import '../global/select2'

export default class extends Controller {
  static targets = ['zipCode', 'city', 'street', 'streetNumber'];

  connect() {
    Promise.resolve().then(() => {
      this._$selet2zipCode = this._setup(this.zipCodeTarget, { param: 'zip_code', onSelect: this._onSelectZipCode, onChange: this._onOpenZipCode, options: { minimumInputLength: 1 } });
      this._$selet2city = this._setup(this.cityTarget, { param: 'city', queryParams: { zip_code: this.zipCodeTarget }, onSelect: this._onSelectCity, onChange: this._onOpenCity });
      this._$selet2street = this._setup(this.streetTarget, { param: 'street', queryParams: { zip_code: this.zipCodeTarget, city: this.cityTarget }, onSelect: this._onSelectStreet, onChange: this._onOpenStreet });
    })
  }

  disconnect() {
    this._$selet2zipCode.select2('destroy')
    this._$selet2city.select2('destroy')
    this._$selet2street.select2('destroy')
  }

  get srcUrl() {
    return new URL(this.data.get('src'));
  }

  get select2Options() {
    return {
      language: 'de',
      minimumInputLength: 0,
      selectOnClose: true
    }
  }

  _setup(element, { param, queryParams = {}, onSelect, onChange, options = {} }) {
    const transport = this._transport(param, queryParams)
    const dropdownParent = this.element.closest('form')

    const select2Options = {
      dropdownParent,
      ...this.select2Options,
      ...options,
      ajax: {
        delay: 250,
        transport
      },
      templateSelection(selection) {
        return selection[param] || selection.text;
      },
      templateResult(result) {
        return result[param];
      },
      escapeMarkup(markup) {
        return markup;
      },
      width: 'style'
    };

    const $select2 = jQuery(element).select2(select2Options)
    $select2.on('select2:select', onSelect.bind(this)).on('change.select2', onChange.bind(this));
    return $select2
  }

  _onSelectZipCode() {
    this._enable(this.cityTarget).then(this._open);
  }

  _onOpenZipCode() {
    this._reset(this.cityTarget);
    this._reset(this.streetTarget);
    this._reset(this.streetNumberTarget);
  }

  _onSelectCity() {
    this._enable(this.streetTarget).then(this._open);
  }

  _onOpenCity() {
    this._reset(this.streetTarget);
    this._reset(this.streetNumberTarget);
  }

  _onSelectStreet() {
    this._enable(this.streetNumberTarget);
  }

  _onOpenStreet() {
    this._reset(this.streetNumberTarget);
  }

  _transport(paramName, queryParams) {
    const func = (params, success, failure) => {
      const { data } = params;
      const { page = 1, term } = data;

      const query = {}

      Object.entries(queryParams).forEach(([key, element]) => {
        query[key] = element.value || '';
      });

      const searchParams = { ...query, page: page, [paramName]: term || '' }

      const url = this.srcUrl
      url.search = new URLSearchParams(searchParams).toString();

      fetch(url)
        .then((response) => response.json())
        .then((resp)=> {
          const { addresses, meta } = resp;

          const addressResults = addresses.map((element) => { return { id: element[paramName], ...element } });
          const more = !!meta.next_page_url;

          const result = {
            results: addressResults,
            pagination: { more }
          }

          return result;
        })
        .then(success, failure);
    }
    return func.bind(this);
  }

  _reset(element) {
    if (!element) {
      return;
    }

    element.value = null;
    element.disabled = true;
    const changeEvent = new Event('change');
    element.dispatchEvent(changeEvent);
  }

  _enable(element) {
    this._reset(element);

    element.disabled = false
    element.focus();
    return Promise.resolve(element);
  }

  _open(element) {
    jQuery(element).select2('open');
  }
}
