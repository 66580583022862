import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.navHeight = document.querySelector('.nav-tabs').clientHeight + 64

    this.shiftplan = this.element
    this.shiftRow = this.shiftplan.querySelector('.st-shiftplan__row:first-child')
    this.firstEmployeeRow = this.shiftplan.querySelector('.st-shiftplan__row:nth-child(2)')
  }

  update(event) {
    this.calcShiftRowWidth(this.shiftplan, this.shiftRow)
    this.setStickyHeader(this.shiftRow, this.firstEmployeeRow, this.navHeight, this.shiftplan)
  }

  onScroll(event) {
    this.shiftRow.scrollLeft = event.target.scrollLeft
  }

  calcShiftRowWidth(a, b) {
    const shiftPlanWidth = a.offsetWidth;
    b.style.width = `${shiftPlanWidth}px`
  }

  setStickyHeader(a, b, c, shiftPlan) {
    const rect = a.getBoundingClientRect()
    const rect2 = b.getBoundingClientRect()
    const cell = a.querySelectorAll('.st-shiftplan__cell:not(:first-child)')
    const first_cell = a.querySelector('.st-shiftplan__cell:first-child')

    if (rect.top <= c) {
      first_cell.style.zIndex = '10'
      cell.forEach(elem => elem.style.zIndex = '9' )
      a.classList.add('sticky-shift-header')
      a.scrollLeft = shiftPlan.scrollLeft
      a.style.top = `${c}px`
      b.style.marginTop = `${rect.height}px`
    }

    if((rect2.top - c) >= rect.height) {
      first_cell.removeAttribute('style')
      cell.forEach(elem => elem.removeAttribute('style'))
      a.classList.remove('sticky-shift-header')
      a.removeAttribute('style')
      b.removeAttribute('style')
    }
  }
}
