import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "input", "element", "toggle", "swap" ]

  connect() {
    this.setDisabled(!this.toggleTarget.checked)
  }

  toggle(e) {
    const disabled = event?.params?.disabled ?? !e.target.checked
    this.setDisabled(disabled)
  }

  swap(){
    this.swapTargets.forEach(element => element.disabled = !element.disabled)
  }

  setDisabled(disabled) {
    this.inputTargets.forEach(element => element.disabled = disabled)

    this.elementTargets.forEach(element => element.classList.toggle('disabled', disabled))
  }
}
