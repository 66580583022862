import gql from "graphql-tag";

export const employees = gql`
  query ($term: String, $first: Int!, $after: String) {
    items: employees(term: $term, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id: uri
        text: name
      }
    }
  }
`;

export const collaborators = gql`
  query ($term: String, $first: Int!, $after: String) {
    items: collaborators(term: $term, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id: uri
        text: name
      }
    }
  }
`;

export const zendeskOrganizations = gql`
  query ($term: String, $first: Int!, $after: String) {
    items: zendeskOrganizations(term: $term, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        text: label
      }
    }
  }
`;

export const tags = gql`
  query ($term: String, $first: Int!, $after: String) {
    items: tags(term: $term, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        text: name
      }
    }
  }
`;

export const futurevalleyResponsibleSales = gql`
  query ($term: String!, $first: Int!, $after: String) {
    items: futurevalleyResponsibleSales(
      term: $term
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id: uri
        text: name
      }
    }
  }
`;

export const futurevalleyResponsibleTalents = gql`
  query ($term: String!, $first: Int!, $after: String) {
    items: futurevalleyResponsibleTalents(
      term: $term
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id: uri
        text: name
      }
    }
  }
`;
