import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'trigger'];

  connect() {
    if(this.hasTriggerTarget) {
      this.update({ target: this.triggerTarget });
    }
  }

  update( { target }) {
    let value = target.value;

    if (!value) {
      value = target.min
    }

    this.inputTargets.forEach(element => element.min = value);
  }
}
