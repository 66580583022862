import { Controller } from "@hotwired/stimulus"
import GoogleMapsCallback from '@utils/google_maps'

export default class extends Controller {
  connect() {
    GoogleMapsCallback.ready().then(() => this.initMap())
  }

  initMap() {
    const center = new window.google.maps.LatLng(0, 0)

    const mapOptions = {
      zoom: 12,
      center: center,
      mapTypeId: 'roadmap',
    };

    this.mapEl = document.createElement('div')
    this.mapEl.style.width = '100%'
    this.mapEl.style.minHeight = '500px'
    this.mapEl.style.height = '100%'

    this.element.appendChild(this.mapEl)

    const map = new window.google.maps.Map(this.mapEl, mapOptions)
    const bounds = new window.google.maps.LatLngBounds()


    const infoWindow = new window.google.maps.InfoWindow()

    const displayInfoWIndow = (marker, url) => {
      infoWindow.close()
      const content = `<turbo-frame id="order-search-map-show" src="${url}" loading="eager" />`
      infoWindow.setContent(content)
      infoWindow.open(map, marker)
    }

    const dataSrc = this.data.get('src')

    fetch(dataSrc)
      .then(response => response.json())
      .then(({data}) => {
        data.forEach(({location, title, url}) => {
          const loc = new window.google.maps.LatLng(location)
          bounds.extend(loc)

          const marker = new window.google.maps.Marker({ map: map, position: loc, title: title })
          marker.addListener('click', () => displayInfoWIndow(marker, url))
        })
    }).then(() => {
      map.fitBounds(bounds)
      map.panToBounds(bounds)
    })
  }

  disconnect() {
    this.mapEl.remove();
  }
}
