import { Controller } from "@hotwired/stimulus"

const apps = import.meta.glob([
'../mailvault/index.js',
'../planning/index.js',
'../qualification_board/index.js',
'../shifts_list/index.js',
'../student_shifts/index.js',
])

export default class extends Controller {
  static values = {
    app: String,
  }

  connect() {
    // Skip action if we are currently showing Turbolinks preview
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return
    }

    this.originalTemplate = this.element.innerHTML

    const status = document.createElement('span')
    status.classList.add('spinner-border', 'spinner-border-sm')
    status.setAttribute('role', 'status')
    this.element.append(status)
    this.status = status

    this.initializeComponent()
  }

  disconnect() {
    if (this.vue) {
      this.vue.unmount()
    }

    this.element.innerHTML = this.originalTemplate
  }

  async initializeComponent() {
    const { default: createApp } = await this.importApp()
    const app = createApp()

    this.status.remove()
    app.mount(this.element)

    this.vue = app
  }

  importApp() {
    // the switch/import construct is required to
    // faccilitate manual js chunks
    switch(this.appValue) {
      case 'qualificationBoard':
        return apps['../qualification_board/index.js']()
      case 'shiftsList':
        return apps['../shifts_list/index.js']()
      case 'studentShifts':
        return apps['../student_shifts/index.js']()
      default:
        const app = `../${this.appValue}/index.js`

        if(!Object.hasOwn(apps, app)) {
          throw `unknown app ${app}`
        }

        return apps[app]()
    }
  }
}
