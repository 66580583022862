import { Controller } from '@hotwired/stimulus'
import { useWindowResize, useClickOutside } from 'stimulus-use'

export default class extends Controller {
	connect() {
		this.items = []
		this.greedyNavActionButton = this.element.querySelector('.greedynav-actionbutton')
		this.greedyNavDropdown = this.element.querySelector('.greedynav-dropdown')
		this.greedyNavVisible = this.element.querySelector('.greedynav-visible')
		this.greedyNavHidden = this.element.querySelector('.greedynav-hidden')
		this.greedyNavStatic = this.element.querySelector('.greedynav-static')

		for (let vlink of this.greedyNavVisible.children) {
			let rect = vlink.getBoundingClientRect()
			this.items.push({
				item: vlink,
				pos: Math.floor(rect.right),
			})
		}

		this.items = this.items.sort((p1, p2) =>
			p1.pos < p2.pos ? 1 : p1.pos > p2.pos ? -1 : 0
		)

		useWindowResize(this)
		useClickOutside(this)
	}

	windowResize({ width }) {
		this.items.forEach((item) => {
			if (item.pos > width - this.greedyNavStatic.offsetWidth - 32) {
				this.greedyNavHidden.insertBefore(item.item, this.greedyNavHidden.firstChild)
			} else {
				this.greedyNavVisible.insertBefore(item.item, this.greedyNavVisible.firstChild)
			}
		})
	}

	clickOutside() {
		this.greedyNavActionButton.classList.remove('is-open')
		this.greedyNavDropdown.classList.add('tw-hidden')
	}

	open() {
		this.greedyNavActionButton.classList.toggle('is-open')
		this.greedyNavDropdown.classList.toggle('tw-hidden')
	}
}
