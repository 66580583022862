import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static activeClasses = ['tw-bg-sea-50', 'hover:tw-bg-sea-50'];

  connect() {
    this.items = this.element.querySelectorAll('a[data-turbo-frame="tab_content"]');
  }

  setActive(event) {
    this.items.forEach((link) => {
      this.constructor.activeClasses.forEach((cls) => link.classList.remove(cls));
    });

    this.constructor.activeClasses.forEach((cls) => event.currentTarget.classList.add(cls));
  }
}
