import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remove() {
    const container = this.element.closest('.additional-language')
    if (container) {
      container.remove()
    }
  }
}
