import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['details']
  static values = {
    closeOnConnect: Boolean
  }

  connect() {
    if (this.closeOnConnectValue) {
      this.closeOnConnect()
    }
  }

  async toggle() {
    this.detailsTargets.forEach(el => el.open = !el.open)
  }

  async close() {
    this.detailsTargets.forEach(el => el.open = false)
  }

  async open() {
    this.detailsTargets.forEach(el => el.open = true)
  }

  async closeOnSuccess(event) {
    if (event?.detail?.formSubmission?.result?.success) {
      this.close()
    }
  }

  async closeOnConnect() {
    console.debug('closeOnConnect')
    this.element.closest('details').open = false
    this.element.remove()
  }
}
