import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
 static values = {
     showModal: Boolean,
   }

   connect() {
     if (this.showModalValue) {
       this.showModal()
     }
   }

   async showModal() {
     this.dialog.showModal()
   }

  get dialog() {
    return this.element.closest('dialog')
  }

  hideModal() {
    this.dialog.close()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->dialog#submitEnd"
  closeOnSuccess(event) {
    if (event.detail.success) {
      this.hideModal()
    }
  }

  // Remove src reference from parent frame element
  // Without this, turbo won't re-open the modal on subsequent click
  onClose() {
    this.element.closest('turbo-frame')?.removeAttribute('src')
    this.dialog.remove()
  }
}
