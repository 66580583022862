import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ['tracker']

  connect() {
    this.pendingPromise = new Promise((resolve) => this.resolvePending = resolve)
  }

  async changed() {
    this.trackerOutlets.forEach((tracker) => tracker.track(this.pendingPromise))
  }

  async cleared() {
    this.resolvePending()
  }

  // outlets are not working
  get trackerOutlets() {
    const nodes = document.querySelectorAll('[data-controller~=unsaved-changes]')
    return Array.from(nodes).map((el) => this.application.getControllerForElementAndIdentifier(el, 'unsaved-changes'))
  }
}
