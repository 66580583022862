import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'toggle' ]
  static classes = [ 'toggle' ]
  static values = {
    selector: String,
  }

  async toggleOne(event) {
    const { target } = event
    const classes = this.toggleClasses

    this.forEachTargetClass(cls => cls.remove(...classes))
    target.classList.add(...classes)
  }

  async add() {
    this.forEachTargetClass(cls => cls.add(...this.toggleClasses))
  }

  async remove() {
    this.forEachTargetClass(cls => cls.remove(...this.toggleClasses))
  }

  async toggleClassList() {
    this.toggleElements(this.targetElements)
  }

  async toggleGlobalSelector() {
    const selector = this.selectorValue
    this.toggleElements(document.querySelectorAll(selector))
  }

  toggleElements(targets) {
    this.forEachTargetClass(cls => cls.toggle(...this.toggleClasses))
  }

  forEachTargetClass(fn) {
    this.targetElements.forEach(el => { fn(el.classList) })
  }

  get targetElements() {
    if (this.hasToggleTarget) {
      return this.toggleTargets
    }

    return [this.element]
  }
}
