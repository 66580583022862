import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ['frame']
  static values = {
    delay: Number,
  }

  initialize() {
    if (this.hasDelayValue) {
      this.updateSuggestions = debounce(this.updateSuggestions, this.delayValue)
    }
  }

  async updateSuggestions(event) {
    this.frameTargets.forEach((el) => {
      const url = this.updateSrc(el.src)
      el.src = url
    })
  }

  updateSrc(src) {
    const url = new URL(src, window.location)
    const search = url.searchParams
    const formData = new FormData(this.element)

    formData.forEach((value, key) => {
      search.set(key, value)
    })

    url.search = search.toString()
    return url
  }
}
