import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery'

export default class extends Controller {
  connect() {
    const buttons = this.element.querySelectorAll('.btn-group-toggle [type=checkbox][checked]')
    buttons.forEach( (btn) => { this.updateBtn(btn) })
  }

  updateBtn(btn) {
    const label = btn.parentElement
    jQuery(label).button('toggle')
    btn.blur()
  }
}
