import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  disableElements() {
    this.element.querySelectorAll(Rails.formDisableSelector).forEach((el) => {
      Promise.resolve().then(() => Rails.disableElement(el))
    })
    this.element.querySelectorAll(Rails.linkDisableSelector).forEach((el) => {
      Promise.resolve().then(() => Rails.disableElement(el))
    })
  }

  enableElements() {
    this.element.querySelectorAll(Rails.formEnableSelector).forEach((el) => {
      Promise.resolve().then(() => Rails.enableElement(el))
    })
    this.element.querySelectorAll(Rails.linkDisableSelector).forEach((el) => {
      Promise.resolve().then(() => Rails.enableElement(el))
    })
  }
}
