export function confirmDialog(msg) {
  return new Promise(function (resolve, reject) {
    const confirmed = window.confirm(msg)

    return confirmed ? resolve(true) : reject(false)
  });
};

export function alertDialog(msg) {
  return new Promise(function (resolve, reject) {
    window.alert(msg)
    return resolve()
  });
};
