import { Controller } from "@hotwired/stimulus"
import { confirmDialog } from '@utils/alert'

export default class extends Controller {
  connect() {
    this.pendingChanges = new Set()
  }

  async track(promise) {
    this.pendingChanges.add(promise)

    const cleanup = () => {
      this.pendingChanges.delete(promise)
    }

    promise.then(cleanup).catch(cleanup)
  }

  leavePage(event) {
    if(!this.hasPendingChanges) {
      return
    }

    event.preventDefault();
    return (event.returnValue = this.msg)
  }

  async confirmPageLeave(event) {
    if(!this.hasPendingChanges) {
      return
    }

    event.preventDefault()

    confirmDialog(this.msg)
      .then((confirmed) => {
        if(confirmed) {
          this.pendingChanges.clear()
          Turbo.visit(event.detail.url)
        }
      })
      .catch(() => {})
  }

  get msg() {
    return "Möchtest du die Seite verlassen? Deine Änderungen werden nicht gespeichert."
  }

  get hasPendingChanges() {
    return this.pendingChanges.size !== 0
  }
}
