import jQuery from 'jquery'
import select2 from 'select2'
select2()

if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) {
  const e = jQuery.fn.select2.amd;
  e.define("select2/i18n/de", [], function() {
    return {
      errorLoading: function() {
        return "Die Ergebnisse konnten nicht geladen werden.";
      },
      inputTooLong: function(e2) {
        return "Bitte " + (e2.input.length - e2.maximum) + " Zeichen weniger eingeben";
      },
      inputTooShort: function(e2) {
        return "Bitte " + (e2.minimum - e2.input.length) + " Zeichen mehr eingeben";
      },
      loadingMore: function() {
        return "Lade mehr Ergebnisse\u2026";
      },
      maximumSelected: function(e2) {
        var n = "Sie k\xF6nnen nur " + e2.maximum + " Element";
        return 1 != e2.maximum && (n += "e"), n += " ausw\xE4hlen";
      },
      noResults: function() {
        return "Keine \xDCbereinstimmungen gefunden";
      },
      searching: function() {
        return "Suche\u2026";
      },
      removeAllItems: function() {
        return "Entferne alle Elemente";
      }
    };
  })
}
