import { Controller } from "@hotwired/stimulus";

export default class Select2ClearController extends Controller {
  static targets = ["select"];

  clear() {
    this.resetTargets({ clear: true });
  }

  empty() {
    this.resetTargets({ clear: false });
  }

  resetTargets(options) {
    this.selectTargets.forEach((el) => this.clearSelect2(el, options));
  }

  clearSelect2(element, { clear }) {
    // we only trigger empty so the value will removed from the form.
    // triggering a change event would cause other dependend fields to be reset as well
    // wich is unwanted for the current usecase in the student search form

    const controller = this.application.getControllerForElementAndIdentifier(
      element,
      "select2"
    );
    if (clear) {
      controller.clear();
    } else {
      controller.empty();
    }
  }
}
