import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery'
import { tryJson } from '../global/utils/try_json'

export default class extends Controller {
  static values = {
    options: Object,
  }

  connect() {
    this.$tooltip = jQuery(this.element).tooltip(this.options)
  }

  disconnect() {
    this.$tooltip.tooltip('dispose')
    const tooltipId = this.element.getAttribute('aria-describedby')
    document.querySelectorAll(`[role=tooltip]#${tooltipId}`).forEach( (el) => el.remove() )
  }

  get options() {
    if (this.hasOptionsValue) {
      return this.optionsValue
    }

    return tryJson(this.data.get("options"))
  }
}
