import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery'

export default class extends Controller {
  copy() {
    this.element.blur();
    navigator.clipboard.writeText(this.value).then(() => {
      this.displayTooltip();
    });
  }

  displayTooltip() {
    this.$tooltip = jQuery(this.element).tooltip({manual: 'manual', title: 'Kopiert'});
    this.$tooltip.tooltip('show');
    setTimeout(() => this.$tooltip.tooltip('dispose'), 1 * 1000);
  }

  get value() {
    return this.data.get('value');
  }
}
