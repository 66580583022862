import { Controller } from "@hotwired/stimulus";

import jQuery from "jquery";
import "../global/select2";

const templateResult = (data) => {
  if (data.element?.dataset?.select2Level) {
    const el = document.createElement("span");
    el.dataset.select2Level = data.element.dataset.select2Level;
    el.innerText = data.text;
    return el;
  } else {
    return data.text;
  }
};

const defaultOptions = {
  language: "de",
  width: "style",
  templateResult,
};

export default class extends Controller {
  static values = {
    hideSearchBox: Boolean
  }

  connect() {
    let elementOptions = {};

    // parsing the options attribute is deprecated. Use the values method (see above) instead
    try {
      elementOptions = JSON.parse(this.data.get("options"));
    } catch (e) { }

    const dropdownParent = this.element.closest("form");
    const options = {
      dropdownParent,
      ...defaultOptions,
      ...elementOptions,
    };

    if (this.hideSearchBoxValue) {
      options.minimumResultsForSearch = Infinity;
    }

    this._innerHTML = this.element.innerHTML;

    this.$select2 = jQuery(this.element).select2(options);
  }

  empty() {
    this.$select2.val(null);
  }

  clear() {
    this.empty();
    this.$select2.trigger("change");
  }

  disconnect() {
    this.$select2.off("select2:open");
    // try {
    //   jQuery(this.element).select2('destroy')
    // } catch(e) {
    //   console.error(e)
    // }
    this.element.innerHTML = this._innerHTML;
  }
}
