import { Controller } from "@hotwired/stimulus";
import jQuery from "jquery";
import { tryJson } from '../global/utils/try_json'

/*
 * usage examples
 *
 * always requires `data-controller="popover"`
 *
 * default bootstrap
 * ```
 * <button type="button" class="btn" data-controller="popover" data-toggle="popover" title="Popover title" data-content="popover content">Click to toggle popover</button>
 * <button type="button" class="btn" data-controller="popover" data-toggle="popover" data-popover-trigger="hover" data-content="popover content">Hover to toggle popover</button>
 * ```
 *
 * HTML content in separate container
 * ```
 * <div data-controller="popover">
 *  <button type="button" class="btn" data-target="popover.toggle">Click to toggle popover</button>
 *  <div>
 *    <h1>Popover Content</h1>
 *    <p>More Popover Content</p>
 *    </div>
 * </div>
 * ```
 *
 * HTML content in separate container & interactable hover
 * ```
 * <div data-controller="popover">
 *  <span data-target="popover.toggle" data-popover-trigger="interactable-hover">Hover to toggle popover</span>
 *  <div>
 *    <h1>Popover Content</h1>
 *    <p>More Popover Content</p>
 *    </div>
 * </div>
 * ```
 *
 * supportet options
 * * `data-popover-trigger` interactable-hover or bootstrap trigger options
 * * `data-popover-placement` bootstrap placement optionsk
 * * `data-popover-options` JSON object to pass into `.popover(options)`
 */

export default class extends Controller {
  static targets = ["content", "toggle", "contentTemplate"];
  static values = {
    options: Object,
    placement: String,
    trigger: String,
  }

  connect() {
    let options = this.options;
    let toggle = this.element;

    if (this.hasContentTarget) {
      const content = this.contentTarget;
      content.style.display = "none";
      options["content"] = content;
      options["html"] = true;
      options["sanitize"] = false;
    }

    if (this.hasContentTemplateTarget) {
      const contentTemplate = this.contentTemplateTarget;

      options["html"] = true;
      options["sanitize"] = false;
      options["content"] = () => {
        return contentTemplate.innerHTML;
      };
    }

    if (!options.hasOwnProperty("placement") && this.optionPlacement) {
      options["placement"] = this.optionPlacement;
    }

    if (this.hasToggleTarget) {
      toggle = this.toggleTarget;
    }

    if (!options.hasOwnProperty("trigger") && this.optionTrigger) {
      options["trigger"] = this.optionTrigger;
    }

    options["container"] = this.element;
    this.setupManualHover(options, toggle);

    this.$popover = jQuery(toggle).popover(options);
    this.$popover.on("show.bs.popover", this.showBsPopover.bind(this));
    this.toggle = toggle;

    this.mutationObserver = new MutationObserver(this.update.bind(this));
    this.mutationObserver.observe(this.element, {
      childList: true,
      subtree: true,
    });
  }

  showBsPopover() {
    if (this.hasContentTarget) {
      this.contentTarget.style.display = null;
    }
  }

  disconnect() {
    this.$popover.popover("dispose");
    this.toggle.removeEventListener("mouseenter", this.onMouseEnter.bind(this));
    this.element.removeEventListener(
      "mouseleave",
      this.onMouseLeave.bind(this)
    );
    this.mutationObserver.disconnect();
  }

  get options() {
    if (this.hasOptionsValue) {
      return this.optionsValue
    }

    return tryJson(this.data.get("options"))
  }

  get optionTrigger() {
    if (this.hasTriggerValue) {
      return this.triggerValue
    }

    return this.data.get("trigger")
  }

  get optionPlacement() {
    if(this.hasPlacementValue) {
      return this.placementValue
    }

    return this.data.get("placement")
  }

  update() {
    this.$popover.popover("update");
  }

  onMouseEnter() {
    this.$popover.popover("show");
  }

  onMouseLeave(event) {
    if (event.target.parentElement.classList.contains("tooltip")) {
      event.relatedTarget.parentElement.classList.contains("tooltip");
      return;
    }
    this.$popover.popover("hide");
  }

  setupManualHover(options, toggle) {
    if (options["trigger"] !== "interactable-hover") {
      return;
    }

    options["trigger"] = "manual";

    toggle.addEventListener("mouseenter", this.onMouseEnter.bind(this));
    this.element.addEventListener("mouseleave", this.onMouseLeave.bind(this));
  }
}
