
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    closest: String,
  }

  connect() {
    if (this.hasClosestValue) {
      const closest = this.element.closest(this.closestValue)
      closest.reload()
    } else {
      location.reload()
    }

    this.element.remove()
  }
}
