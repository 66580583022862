import 'bootstrap'

// @see https://github.com/hotwired/turbo-rails#installation
// @see https://dev.to/coorasse/from-turbolinks-to-turbo-31jl
import { Turbo } from '@hotwired/turbo-rails'
window.Turbo = Turbo

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}

import Rails from '@rails/ujs'
window.Rails = Rails // legacy

try {
  Rails.start()
} catch(e) {
  console.debug(e)
}

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "trix/dist/trix.css"
import Trix from "trix"
window.Trix = Trix // hr tag
Trix.config.blockAttributes.heading1.tagName = "h3"

// import 'channels' // enable for actioncable support

import '../shinobi'

if (process.env.NODE_ENV === 'development') {
  try {
    window.$$StimulusApp$$.debug = true
  } catch(e) {
    console.debug(e)
  }
}

import '../controllers'
