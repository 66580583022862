import { Controller } from "@hotwired/stimulus";
import { resetInput } from "../global/utils/form";

export default class extends Controller {
  static targets = ["input"];

  resetIfEmpty(e) {
    if (e?.target.value === "") {
      this.resetTargets();
    }
  }

  resetTargets() {
    this.inputTargets.forEach((el) => resetInput(el));
  }
}
