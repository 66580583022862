import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    cancelButton: 'jv-button jv-button-outline',
  },
  buttonsStyling: false,
  focusConfirm: false
})

export default class extends Controller {
  async onFetchResponse(event) {
    const { fetchResponse } = event.detail
    const { response } = fetchResponse
    const { status } = response

    if (status !== 422) {
      return
    }

    const json = await response.json()

    if ('confirm' in json) {
      event.preventDefault()
      this.handleForceConfirm(json.confirm, event.target)
      return
    }

    if ('alert' in json) {
      event.preventDefault()
      this.handleAlert(json.alert)
      return
    }
  }

  handleForceConfirm(alert, target) {
    this.showAlert(alert).then(result => {
      if(result.value === true) {
        this.forceSubmit(target)
      }
    })
  }

  handleAlert(alert) {
    const options = {
      icon: 'error',
      confirmButtonText: 'Ok',
      buttonsStyling: true,
      ... alert
    }

    this.showAlert(options)
  }

  forceSubmit(target) {
    const form = target.closest('form')
    const forceInput = form.querySelector(`input[name="force"]`)
    forceInput.value = true

    window.Rails.fire(form, 'submit')
  }

  showAlert(options) {
    return swalWithBootstrapButtons.fire(options)
  }
}
