import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "select", "textarea" ]
  static OPTION_WITHOUT_QUALIFICATIONS = '1'
  static NO_QUALIFICATIONS_NEEDED_MSG = 'Keine Qualifikation erforderlich'

  connect() {
    this.updateTextarea()
  }

  updateTextarea() {
    if (this.selectTarget.value === this.constructor.OPTION_WITHOUT_QUALIFICATIONS) {
      this.textareaTarget.value = this.constructor.NO_QUALIFICATIONS_NEEDED_MSG
      this.textareaTarget.disabled = true
      this.textareaTarget.classList.remove('is-invalid') // remove any validation errors
    } else {
      if (this.textareaTarget.value === this.constructor.NO_QUALIFICATIONS_NEEDED_MSG) {
        this.textareaTarget.value = ''
      }
      this.textareaTarget.disabled = false
    }
  }
}
