import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'item', 'bubble' ]

  static values = {
    on: String,
    addClass: String,
    removeClass: String
  }

  itemTargetConnected(element) {
    this.personalize(element)
  }

  bubbleTargetConnected(element) {
    if(element.dataset['commentsUnseenBy'].split(',').includes(this.onValue)) {
      element.classList.remove('hidden')
      element.classList.add('tw-relative')
    } else {
      element.classList.remove('tw-relative')
      element.classList.add('hidden')
    }
  }

  personalize(element) {
    if (element.dataset['personalizeOn'] !== this.onValue) return

    this.personalizeClass(element)
  }

  personalizeClass(element) {
    this.personalizeRemoveClass(element)
    this.personalizeAddClass(element)
  }

  personalizeAddClass(element) {
    const klass = element.dataset['personalizeAddClass'] || this.addClassValue
    if (klass === '') return

    element.classList.add(...klass.split(' '))
  }

  personalizeRemoveClass(element) {
    const klass = element.dataset['personalizeRemoveClass'] || this.removeClassValue
    if (klass === '') return

    element.classList.remove(...klass.split(' '))
  }
}
