import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 100 },
  }

  static targets = [ 'form' ]

  initialize() {
    this.submit = debounce(this.submit, this.delayValue)
  }

  submit() {
    this.target.requestSubmit()
  }

  get target() {
    if (this.hasFormTarget) {
      return this.formTarget
    } else {
      return this.element
    }
  }
}
