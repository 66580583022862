import { Controller } from "@hotwired/stimulus"

// @see https://discuss.hotwired.dev/t/empty-state-with-turbo-stream-in-rails/2068
// @see other possible solution: https://dev.to/phawk/hotwire-best-practices-for-stimulus-40e
export default class extends Controller {
  static targets = [ "emptyState", "item" ]

  connect() {
    this.observer = new MutationObserver(this.update.bind(this));
    this.observer.observe(this.element, {
      childList: true,
      attributes: false,
      subtree: true
    });
    this.update();
  }

  disconnect() {
    this.observer.disconnect();
  }

  update() {
    if (this.itemTargets.length > 0) {
      this.emptyStateTarget.classList.add('d-none')
    } else {
      this.emptyStateTarget.classList.remove('d-none')
    }
  }
}
