import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this._timeout = setTimeout(() => { this.onTimeout() }, this.delay)
  }

  disconnect() {
    clearTimeout(this._timeout)
  }

  onTimeout() {
    const event = new CustomEvent(this.eventName)
    this.element.dispatchEvent(event)
  }

  get delay() {
    return 4000
  }

  get eventName() {
    return 'delay'
  }
}
