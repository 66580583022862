import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["messages", "templateAlert", "templateNotice"]

  connect() {
    this.setMessages(this.initialMessages)
  }

  disconnect() {
    this.clear()
  }

  setMessages(messages) {
    this.addNotice(messages['notice'])
    this.addAlert(messages['alert'])
  }

  clear() {
    this.messagesTarget.innerHTML = ''
  }

  addNotice(msg) {
    this.appendMessage(msg, this.templateNoticeTarget)
  }

  addAlert(msg) {
    this.appendMessage(msg, this.templateAlertTarget)
  }

  appendMessage(msg, template) {
    if (!msg) {
      return
    }

    const view = this._renderTemplate(template, msg)
    this.messagesTarget.appendChild(view)
  }

  onSetMessages(event) {
    const { messages } = event.detail;
    this.setMessages(messages);
  }

  get initialMessages() {
    if (this.data.has('messages')) {
      const msg = this.data.get('messages')
      this.data.delete('messages')
      return JSON.parse(msg)
    } else {
      return {}
    }
  }

  _renderTemplate(template, msg) {
    const view = template.content.cloneNode(true)
    const text = view.querySelector('.alert-text')
    text.textContent = msg
    return view.firstElementChild
  }
}

window.Shinobi = {
  ...window.Shinobi,
  setFlashMessages(msg) {
    window.Rails.fire(document, 'flash:setMessages', { messages: msg })
  }
}
