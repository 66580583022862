import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selector: String,
    syncAttribute: String
  }

  static targets = ['sync']

  connect() {
    this.sync()
  }

  async selectAll(event) {
    const selector = event.params.selector
    this.eachElement(element => element.checked = true, { selector })
  }

  async selectNone(event) {
    const selector = event.params.selector
    this.eachElement(element => element.checked = false, { selector })
  }

  async selectToggle(event) {
    const selector = event.params.selector
    this.eachElement(element => element.checked = !element.checked, selector)
  }

  eachElement(fn, { selector }) {
    const sel = this.checkBoxSelector(selector)
    this.allElements(sel).forEach(fn)
  }

  allElements(selector) {
    const elements = this.target.querySelectorAll(this.checkBoxSelector(selector))
    return elements
  }

  sync(event) {
    if(!this.hasSyncAttributeValue) {
      return
    }

    this.syncTargets.forEach(
      (target) => {
        const sel = `[${this.syncAttributeValue}=${target.getAttribute(this.syncAttributeValue)}]`
        const elements = Array.from(this.allElements(sel)).filter(el => el !== target)

        if(elements.every(el => el.checked === true)) {
          target.checked = true
        } else {
          target.checked = false
        }

      }
    )
  }

  checkBoxSelector(selector) {
    if (selector) {
      return selector
    }

    if (this.hasSelectorValue){
      return this.selectorValue
    }

    return 'input[type=checkbox]'
  }

  get target() {
    return this.element
  }
}
