import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  redirect(event) {
    const [response, status, xhr] = event.detail
    const loginLocation = xhr.getResponseHeader('X-Login-Location')

    if (xhr.status !== 401 || !loginLocation) {
      return
    }

    window.location.assign(loginLocation)
  }
}
