// Horizontal rule
// @see https://github.com/basecamp/trix/issues/570
// @see https://github.com/basecamp/trix/issues/920
// @see ActionText variant: https://github.com/rails/rails/issues/35218#issuecomment-512825833
addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const blockTools = toolbarElement.querySelector("[data-trix-button-group=block-tools]")
  blockTools.insertAdjacentHTML("beforeend", `
        <button type="button" class="trix-button" data-trix-action="x-horizontal-rule" title="Horizontal Rule" tabindex="-1">━</button>
      `)
})

addEventListener("trix-action-invoke", event => {
  if (event.actionName == "x-horizontal-rule") {
    const { editor } = event.target
    const attachment = new Trix.Attachment({ content: "<hr>", contentType: "application/vnd.trix.horizontal-rule.html" })
    editor.insertAttachment(attachment)
  }
})
