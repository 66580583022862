import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'

import gql from 'graphql-tag'

const buildApolloClient = ({ uri, ...options }) => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      new HttpLink({
        uri,
        credentials: 'same-origin'
      })
    ]),
    cache: new InMemoryCache(),
    ...options
  })
}

export const apolloClient = buildApolloClient({
  uri: '/api/graphql',
});

export const apolloAutocompleteClient = buildApolloClient({
  uri: '/infrastructure/autocomplete/graphql',
});

export const apolloShiftPlanningClient = buildApolloClient({
  uri: '/shift_planning/graphql'
})

export const apolloQualificationsClient = buildApolloClient({
  uri: '/qualifications/graphql'
})

//window.__APOLLO_CLIENT__ = apolloQualificationsClient

// NOTE: See https://jkettmann.com/4-ways-to-handle-local-state-when-using-apollo-and-graphql/
// This was the only documentation that really worked. Other stuff with `clientState` did not work somehow...
// https://medium.com/the-notice-board/life-without-redux-using-apollo-for-local-state-d32b020ff4d3
// https://www.apollographql.com/docs/react/data/local-state/
// https://apollo.vuejs.org/guide/local-state.html#extending-a-remote-graphql-schema-locally
// I think one problem ist ApolloBoost (with seemed to be depreacted) and the normal client
const resolvers = {
  Shift: {
    isHidden(shift) { return shift.isHidden || false },
    pendingStudentCount(shift) { return shift.pendingStudentCount || 0 },
    sortKey(shift) { return `${shift.date}\u{2060}${shift.displayName}\u{2060}${shift.id}` },
    inPast(shift) { return shift.inPast || shift.secondsUntilEnd < 0 }
  },
  Student: {
    workingHours(student) { return student.workingHours || 0 },
    sortName(student) { return `${student.lastName}\u{2060}${student.firstName}` },
  },
  Mutation: {
    toggleShift(_, args, context) {
      const fragment = gql`
        fragment shiftToToggle on Shift {
          isHidden
        }
      `
      updateCacheFragment(context, fragment, 'Shift', args.id, (shift) => ({ ...shift, isHidden: !shift.isHidden }))
    },
    markShiftAsPast(_, { id }, context) {
      const fragment = gql`
        fragment shiftToMark on Shift {
          inPast
        }
      `
      updateCacheFragment(context, fragment, 'Shift', id, (shift) => ({ ...shift, inPast: true }))
    },
    updateShiftPendingStudentCount(_, { id, pendingStudentCount }, context) {
      const fragment = gql`
        fragment updatePendingStudentCount on Shift {
          pendingStudentCount
        }
      `
      updateCacheFragment(context, fragment, 'Shift', id, (data) => ({ ...data, pendingStudentCount }))
    },
    updateStudentWorkingHours(_, { id, workingHours }, context) {
      const fragment = gql`
        fragment updateStudentWorkingHours on Student {
          workingHours
        }
      `
      updateCacheFragment(context, fragment, 'Student', id, (data) => ({ ...data, workingHours }))
    },
  }
}

const updateCacheFragment = (context, fragment, typename, id, callback) => {
  const { getCacheKey, cache } = context
  const cacheKey = getCacheKey({ id, __typename: typename })
  const cachedData = cache.readFragment({ fragment, id: cacheKey })
  const newFragmentData = callback(cachedData)

  cache.writeFragment({ fragment, id: cacheKey, data: newFragmentData })
}

export const apolloShiftSchedulingClient = buildApolloClient({
  uri: '/shift_scheduling/graphql',
  resolvers: resolvers
})
