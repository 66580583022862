
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "zipCodeSource", "citySource", "streetSource", "streetNumberSource", "zipCodeDestination", "cityDestination", "streetDestination", "streetNumberDestination", "shouldCopy" ]

  connect() {
    this.listenOnShouldCopy();

    this.listenOnSource('zipCode');
    this.listenOnSource('city');
    this.listenOnSource('street');
    this.listenOnSource('streetNumber');
  }

  disconnect() {
    this.disconnectFromShouldCopy();

    this.disconnectFromSource('zipCode');
    this.disconnectFromSource('city');
    this.disconnectFromSource('street');
    this.disconnectFromSource('streetNumber');
  }

  get shouldCopy() {
    return this.shouldCopyTarget.checked === true;
  }

  copyAddress(event) {
    if(!this.shouldCopy) {
      return;
    }

    this.copySelect('zipCode');
    this.copySelect('city');
    this.copySelect('street');
    this.copyInput('streetNumber');
  }

  releaseDestinationInput(name) {
    const dest = this.getDestination(name);
    dest.disabled = false;
    dest.readOnly = false;
  }

  copyInput(name, setWith = (input, value) => { input.value = value; }) {
    const source = this.getSource(name);
    const dest = this.getDestination(name);

    const value = source.value;

    dest.readOnly = true;
    dest.disabled = true;

    setWith(dest, value);

    const changeEvent = new Event('change');
    dest.dispatchEvent(changeEvent);
  }

  copySelect(name) {
    this.copyInput(name, (input, value) => {
      const val = value ? value : '';

      const option = new Option(value, value, false, true);
      input.append(option);
    });
  }

  copyFromSourceChanged(event) {
    if(!this.shouldCopy) {
      this.releaseDestinationInput('zipCode');
      this.releaseDestinationInput('city');
      this.releaseDestinationInput('street');
      this.releaseDestinationInput('streetNumber');
    }
  }

  getSource(name) {
    return this[`${name}SourceTarget`];
  }

  getDestination(name) {
    return this[`${name}DestinationTarget`];
  }

  listenOnSource(name) {
    const element = this.getSource(name)
    element.addEventListener('change', this.copyAddress.bind(this));
  }

  disconnectFromSource(name) {
    const element = this.getSource(name)
    element.removeEventListener('change', this.copyAddress.bind(this))
  }

  listenOnShouldCopy() {
    this.shouldCopyTarget.addEventListener('change', this.copyFromSourceChanged.bind(this));
  }

  disconnectFromShouldCopy() {
    this.shouldCopyTarget.removeEventListener('change', this.copyFromSourceChanged.bind(this));
  }
}
