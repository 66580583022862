import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    dueTo: String,
  }

  submit(event) {
    event.preventDefault();
    const dueTo = this.dueToValue;
    const faelligBisSelect = document.querySelector('#faellig_bis')
    faelligBisSelect.value = dueTo;
    document.querySelector('#tasks_search').requestSubmit();
  }
}
