import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ['menu']
  static values = { open: Boolean }
  static classes = ['toggle']

  connect() {
    useClickOutside(this)
    this.element.setAttribute("aria-haspopup", "true")
  }

  async toggle() {
    this.openValue = !this.openValue
  }

  async show(){
     this.openValue = true
  }

  async hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.close()
    }
  }

  async close() {
    this.openValue = false
  }

  async clickOutside() {
    this.close()
  }

  openValueChanged() {
    if (this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show() {
    this.menuTarget.classList.remove(this.toggleClass)
    this.element.setAttribute("aria-expanded", "true")
    this.dispatch("show")

    this.menuTarget.querySelector("input")?.focus()
  }

  _hide(cb) {
    this.element.setAttribute("aria-expanded", "false")
    this.menuTarget.classList.add(this.toggleClass)
    this.dispatch("hide")
  }
}
