import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ['clickReceiver']

  static values = {
    delay: Number,
  }

  initialize() {
    if (this.hasDelayValue) {
      this.forwardClick = debounce(this.forwardClick, this.delayValue)
    }
  }

  forwardClick(event) {
    this.clickReceiverTargets.forEach((el) => {
      if(el.contains(event.target)) {
        return
      }
      el.click()
    })
  }

  silenceFormSubission(event) {
    const { submitter } = event.detail.formSubmission
    if (this.clickReceiverTargets.some((el) => el === submitter)) {
      event.stopPropagation()
    }
  }
}
