import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use"

// @see https://github.com/hotwired/turbo/issues/109#issuecomment-761538869
export default class extends Controller {

  // vector has to be an Array of objects, which are compared to the sort code object
  static values = {
    vector: Array
  }

  connect() {
    useMutation(this, { childList: true })
    this.sortChildren()
  }

  mutate(_mutations) {
    this.sortChildren()
  }

  sortChildren() {
    if (this.elementsAreSorted(this.children)) return

    this.children
      .sort(this.compareElements.bind(this))
      .forEach(this.append)
  }

  get children() {
    return Array.from(this.element.children)
  }

  append = child => this.element.append(child)

  elementsAreSorted([left, ...rights]) {
    for (const right of rights) {
      if (this.compareElements(left, right) > 0) return false
      left = right
    }
    return true
  }

  compareElements(left, right) {
    const leftSortCode = this.getSortCode(left)
    const rightSortCode = this.getSortCode(right)

    if (!leftSortCode || !rightSortCode) {
      return 0
    }

    if (this.hasVectorValue) {
      var result = 0
      // NOTE: Currently multiple vectors does not really do anything useful.
      // We would need to categorize by vector index and then use this category in our sorting.
      this.vectorValue.forEach((vector) => {
        result = this.compareObjectsWithVector(leftSortCode, rightSortCode, vector)
        if (result !== 0) return
      })
      return result
    } else if (Array.isArray(leftSortCode) && Array.isArray(rightSortCode)) {
      return this.compareArrays(leftSortCode, rightSortCode)
    } else {
      return rightSortCode - leftSortCode
    }
  }

  getSortCode(element) {
    return JSON.parse(element.getAttribute('data-sort-code'))
  }

  compareArrays(leftComparison, rightComparison) {
    for (const index in leftComparison) {
      const leftValue = leftComparison[index]
      const rightValue = rightComparison[index]

      if (leftValue === rightValue) {
        continue
      }

      if (leftValue < rightValue) {
        return -1
      } else {
        return 1
      }
    }

    return 0
  }

  // Compares the given objects with an vector and with each other
  // Returns -1 if left should go before right
  // Returns 0 if left and right are equal
  // Returns 1 if left should go after right
  // Example:
  //  leftComparison = [0, 0, 0, 99]
  //  rightComparison = [0, 1, 47, 101]
  //  => -1
  //  0 === 0 => continue
  //  0 < 1 => -1 break
  compareObjectsWithVector(left, right, vector) {
    const leftComparison = this.compareObjects(vector, left)
    const rightComparison = this.compareObjects(vector, right)

    return this.compareArrays(leftComparison, rightComparison)
  }

  // Compares the given objects with each other
  // Returns an array of numbers of same size as the left object
  // takes other value if property is undefined
  // checks for inclusion if one value is an array
  // Example:
  //   let vector = { a: 'foo', b: true, c: 42, d: undefined }
  //   let codeA = { a: ['foo', 'bar'], b: true, c: 42, d: 99 }
  //   let codeB = { a: 'foo', b: false, c: 89, d: 101 }
  //
  //   compareObjects(vector, left) => [0, 0, 0, 99]
  //     [foo in foo/bar, true === true, 42 - 42 = 0, undefined -> 99]
  //   compareObjects(vector, right) => [0, 1, 47, 101]
  //     [foo === foo, true !== false, 89 - 42 = 47, undefined -> 101]
  compareObjects(left, right) {
    const result = []
    for (const property in left) {
      const leftValue = left[property]
      const rightValue = right[property]
      if (typeof(rightValue) === 'undefined' || rightValue === null) {
        result.push(leftValue)
        continue
      }
      if (Array.isArray(rightValue)) {
        (rightValue.includes(leftValue)) ? result.push(0) : result.push(1)
        continue
      }

      switch(typeof leftValue) {
        case 'string':
          (leftValue === rightValue) ? result.push(0) : result.push(1)
          break
        case 'number':
          result.push(rightValue - leftValue)
          break
        case 'undefined':
          result.push(rightValue)
          break
        default:
          if (Array.isArray(leftValue)) {
            (leftValue.includes(rightValue)) ? result.push(0) : result.push(1)
            break
          }
          if (leftValue === null) {
            result.push(rightValue)
            break
          }
          (leftValue === rightValue) ? result.push(0) : result.push(1)
      }
    }
    return result
  }
}
