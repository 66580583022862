import GoogleMapsCallback from '@utils/google_maps'

let promiseResolver = () => {}

const promise = new Promise((res) => {
  promiseResolver = res
})

window.googleMapCallback = () => {
  promiseResolver()
}

GoogleMapsCallback.init(promise)
