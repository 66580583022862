import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    target: String
  }

  connect() {
    window.open(this.urlValue, this.targetValue)
    this.element.remove()
  }
}
