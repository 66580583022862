export default class {
  static promise;

  static init(promise) {
    this.promise = promise
  }

  static ready() {
    return this.promise
  }
}
