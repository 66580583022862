import delay from 'lodash/delay'
import jQuery from 'jquery'

document.addEventListener('turbo:load', (_event) => {
  document.querySelectorAll('select:not([data-behaviour]):not([data-controller]):not([data-address-select2-target])').forEach((el) => {
      console.warn('legacy select', el);
      el.setAttribute('data-controller', 'select2');
  });
});

// TODO: is this still needed?
// Disable triggering select2 open event when unselecting (removing) an item from multiselect list
// see https://github.com/select2/select2/issues/3320#issuecomment-524153140
// select2 uses jQuery events
jQuery(document).on('select2:unselect', (event) => {
  const $el = jQuery(event.target);
  const eventName = 'select2:opening.cancelOpen';

  $el.on(eventName, (event) => {
    event.preventDefault();
  });
  delay(() => { $el.off(eventName) }, 100);
})

jQuery(document).on('select2:open', () => {
  setTimeout(() => { document.querySelector('.select2-container--open .select2-search__field').focus() }, 0)
})

const observer = new MutationObserver((mutations, observer) => {
  document.querySelectorAll('.select2-selection__clear:not([data-controller*="tooltip"])').forEach((el) => {
    el.setAttribute('data-placement', 'bottom');
    el.setAttribute('data-controller', 'tooltip');
  });
});
observer.observe(document, { childList: true, subtree: true });

jQuery(document).on('change.select2', (jqevent) => {
  if ('originalEvent' in jqevent) {
    return;
  }

  const { target } = jqevent
  const event = new Event('change')

  target.dispatchEvent(event)
})
